import {useState} from 'react';
import Sidebar from './Sidebar';

function Offers() {

    const [offertag, setOffertag] = useState("all");

    return (
        <div className="main">
            <Sidebar selected="offers" />
            <div className="offers">
                <h2><b>Offers & Reccomendations</b></h2>
                <h3><b>Credit Cards</b><p>Showing 85 Results</p></h3>
                
                <div className="searchbar">
                    <h3 onClick={() => setOffertag("all")} data-selected={offertag === "all"}><i className="fas fa-credit-card"></i>All</h3>
                    <h3 onClick={() => setOffertag("cashback")} data-selected={offertag === "cashback"}><i className="fas fa-dollar-sign"></i>Cashback</h3>
                    <h3 onClick={() => setOffertag("rewards")} data-selected={offertag === "rewards"}><i className="fas fa-trophy"></i>Rewards</h3>
                    <h3 onClick={() => setOffertag("build_credit")} data-selected={offertag === "build_credit"}><i className="fas fa-thumbs-up"></i>Build Credit</h3>
                    <h3 onClick={() => setOffertag("cut_costs")} data-selected={offertag === "cut_costs"}><i className="fas fa-tag"></i>Cut Costs</h3>
                </div>
                <div className="panel offer">
                    <h3><i className="fas fa-check-circle"></i>Your approval odds are very good<i className="fas fa-info-circle"></i></h3>
                    <hr className="thick" />
                    <div className="offercols">
                        <div>
                            <img src="example_visa.jpg" alt="" />
                        </div>
                        <div>
                            <label>Triangle™ Mastercard®</label><br />
                        </div>
                    </div>
                </div>
                <div className="panel offer">
                    <h3><i className="fas fa-check-circle"></i>Your approval odds are very good<i className="fas fa-info-circle"></i></h3>
                    <hr className="thick" />
                    <div className="offercols">
                        <div>
                            <img src="example_visa.jpg" alt="" />
                        </div>
                        <div>
                            <label>Triangle™ Mastercard®</label><br />
                        </div>
                    </div>
                </div>
                <div className="panel offer">
                    <h3><i className="fas fa-check-circle"></i>Your approval odds are very good<i className="fas fa-info-circle"></i></h3>
                    <hr className="thick" />
                    <div className="offercols">
                        <div>
                            <img src="example_visa.jpg" alt="" />
                        </div>
                        <div>
                            <label>Triangle™ Mastercard®</label><br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Offers;