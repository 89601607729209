import Sidebar from './Sidebar';

function Details() {

    return (
        <div className="main">
            <Sidebar selected="details" />
            <div className="details">
                
                <h2><b>Personal Info</b></h2>
                <div className="panel fields">
                    
                    <div className="field field-text field-dual" data-error={false}>
                        <label>Full name</label>
                        <input name="firstname" placeholder="First" />
                        <input name="lastname" placeholder="Last" />
                    </div>

                    <div className="field field-text" data-error={false}>
                        <label>Email address</label>
                        <i className="fa-solid fa-envelope"></i>
                        <input name="email" placeholder="hello@contact.ca" />
                    </div>

                    <div className="field right field-text">
                        <label>Primary phone</label>
                        <i className="fa-solid fa-phone"></i>
                        <input name="phone" placeholder="(###) ###-####" disabled />
                    </div>

                    <div className="field field-select" data-error={false}>
                        <label>What is the best time of day to reach you?</label>
                        <i className="fa-solid fa-clock"></i>
                        <select name="availablity">
                            <option disabled selected value="0">Select One</option>
                            <option value="0">Daytime</option>
                            <option value="1">Evening</option>
                            <option value="2">Weekends</option>
                        </select>
                    </div>

                    <div className="field right field-text" data-error={false}>
                        <label>Date of birth</label>
                        <i className="fa-solid fa-birthday-cake"></i>
                        <input name="dateofbirth" placeholder="MM/DD/YYYY" />
                    </div>

                    <div className="field field-address" data-error={false}>
                        <label>Primary residence</label>
                        <i className="fa-solid fa-house"></i>
                        <input placeholder="Address Lookup" />
                        <div>
                            <input name="streetaddress" placeholder="Street Address" />
                            <input name="city" placeholder="City" />
                            <input name="province" placeholder="Province" />
                            <input name="postalcode" placeholder="Postal Code" />
                        </div>
                    </div>

                    <div className="field field-select" data-error={false}>
                        <label>How long have you lived at this address?</label>
                        <i className="fa-solid fa-calendar"></i>
                        <select name="residenceduration">
                            <option disabled selected value="0">Select One</option>
                            <option value="1">3 - 6 Months</option>
                            <option value="2">6 - 12 Months</option>
                            <option value="3">12 Months - 2 Years</option>
                            <option value="4">2 Years +</option>
                            <option value="5">Less Than 3 Months</option>
                        </select>
                    </div>

                </div>

                <hr />

                <h2><b>Financial Info</b></h2>
                <div className="panel fields">
                    
                    <div className="field field-select" data-error={false}>
                        <label>What is your employment status</label>
                        <i className="fa-solid fa-briefcase"></i>
                        <select name="employmentstatus">
                            <option disabled selected value="0">Select One</option>
                            <option value="1">Employed</option>
                            <option value="2">Self Employed</option>
                            <option value="3">Retired</option>
                            <option value="4">Disability</option>
                            <option value="5">Other</option>
                            <option value="6">Unemployed</option>
                        </select>
                    </div>

                    {true ? <>
                        <div className="field right field-text" data-error={false}>
                            <label>Where do you work?</label>
                            <i className="fa-solid fa-building"></i>
                            <input name="company" placeholder="Company Name" />
                        </div>

                        <div className="field field-text" data-error={false}>
                            <label>What is your position?</label>
                            <i className="fa-solid fa-user-tie"></i>
                            <input name="jobtitle" placeholder="Job Title" />
                        </div>
                    </> : <></>}

                    <div className="field right field-text" data-error={false}>
                        <label>Gross monthly income</label>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <input name="income" placeholder="0.00" />
                    </div>

                    <div className="field field-select" data-error={false}>
                        <label>How long have you had this income?</label>
                        <i className="fa-solid fa-calendar"></i>
                        <select name="employmentduration">
                            <option disabled selected value="0">Select One</option>
                            <option value="1">3 - 6 Months</option>
                            <option value="2">6 - 12 Months</option>
                            <option value="3">12 Months - 2 Years</option>
                            <option value="4">2 Years +</option>
                            <option value="5">Less Than 3 Months</option>
                        </select>
                    </div>

                    <div className="field right field-select" data-error={false}>
                        <label>Do you own a vehicle?</label>
                        <i className="fa-solid fa-car-side"></i>
                        <select name="vehicleownership">
                            <option disabled selected value="0">Select One</option>
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                        </select>
                    </div>

                    <div className="field field-select" data-error={false}>
                        <label>Do you own or rent your home?</label>
                        <i className="fa-solid fa-house-circle-check"></i>
                        <select name="homeownership">
                            <option disabled selected value="0">Select One</option>
                            <option value="1">Own</option>
                            <option value="2">Rent</option>
                        </select>
                    </div>

                    {true ? <>
                        <div className="field right field-text" data-error={false}>
                            <label>What are your monthly (rent or mortgage) payments?</label>
                            <i className="fa-solid fa-dollar-sign"></i>
                            <input name="residencepayments" placeholder="0.00" />
                        </div>
                    </> : <></>}
                    
                </div>

            </div>
        </div>
    );
  }
  
  export default Details;