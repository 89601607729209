import {Link} from 'react-router-dom';

function Dashboard( { selected } ) {
    return (
        <div className="sidebar">
            <Link to="/" data-selected={ selected === "dashboard" }><i className="fas fa-home"></i>Dashboard</Link>
            <Link to="/details" data-selected={ selected === "details" }><i className="fas fa-address-card"></i>Account Details</Link>
            <Link to="/offers" data-selected={ selected === "offers" }><i className="fas fa-bullhorn"></i>Offers</Link>
            <Link to="/applications" data-selected={ selected === "applications" }><i className="fas fa-pencil-alt"></i>Applications</Link>
            <Link to="/support" data-selected={ selected === "support" }><i className="fas fa-question-circle"></i>Support</Link>
        </div>
    );
  }
  
  export default Dashboard;