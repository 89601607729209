import {useState} from 'react';
import Sidebar from './Sidebar';

function Details() {

    const [offertag, setOffertag] = useState("all");

    return (
        <div className="main">
            <Sidebar selected="dashboard" />
            <div className="dashboard">
                <h2><b>Hello,</b> Josh Maxwell</h2>
                <div className="panel twocol">
                    <div>
                        <h3>Personal Info</h3>
                        <hr />
                        <label>Email</label><br />
                        <span>josh@consumergenius.ca</span><br />
                        <label>Primary Phone</label><br />
                        <span>(807) 632-1339</span><br />
                        <label>Address</label><br />
                        <span>123 Fake St, Fake City, Ontario, P7A 2T7</span><br />
                    </div>
                    <div>
                        <h3>Recent Applications</h3>
                        <hr />
                        <label>Triangle™ Mastercard®</label><br />
                        <span><label>Status</label><i className="fas fa-clock"></i>Pending</span><br />
                        <label>MBNA True Line® Mastercard®</label><br />
                        <span><label>Status</label><i className="fas fa-check-circle"></i>Approved</span><br />
                    </div>
                </div>
                <hr />
                <h2><b>Offers tailored to your score</b></h2>
                <div className="searchbar">
                    <h3 onClick={() => setOffertag("all")} data-selected={offertag === "all"}><i className="fas fa-credit-card"></i>All</h3>
                    <h3 onClick={() => setOffertag("cashback")} data-selected={offertag === "cashback"}><i className="fas fa-dollar-sign"></i>Cashback</h3>
                    <h3 onClick={() => setOffertag("rewards")} data-selected={offertag === "rewards"}><i className="fas fa-trophy"></i>Rewards</h3>
                    <h3 onClick={() => setOffertag("build_credit")} data-selected={offertag === "build_credit"}><i className="fas fa-thumbs-up"></i>Build Credit</h3>
                    <h3 onClick={() => setOffertag("cut_costs")} data-selected={offertag === "cut_costs"}><i className="fas fa-tag"></i>Cut Costs</h3>
                </div>
                <div className="panel offer">
                    <h3><i className="fas fa-check-circle"></i>Your approval odds are very good<i className="fas fa-info-circle"></i></h3>
                    <hr className="thick" />
                    <div className="offercols">
                        <div>
                            <img src="example_visa.jpg" alt="" />
                        </div>
                        <div>
                            <label>Triangle™ Mastercard®</label><br />
                        </div>
                    </div>
                </div>
                <div className="panel offer">
                    <h3><i className="fas fa-check-circle"></i>Your approval odds are very good<i className="fas fa-info-circle"></i></h3>
                    <hr className="thick" />
                    <div className="offercols">
                        <div>
                            <img src="example_visa.jpg" alt="" />
                        </div>
                        <div>
                            <label>Triangle™ Mastercard®</label><br />
                        </div>
                    </div>
                </div>
                <div className="panel offer">
                    <h3><i className="fas fa-check-circle"></i>Your approval odds are very good<i className="fas fa-info-circle"></i></h3>
                    <hr className="thick" />
                    <div className="offercols">
                        <div>
                            <img src="example_visa.jpg" alt="" />
                        </div>
                        <div>
                            <label>Triangle™ Mastercard®</label><br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Details;