import {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import object from './json_examples/support.json';

function Support() {

    const [selectedCategory, setCategory] = useState(null);
    const [selectedQuestion, setQuestion] = useState(null);
    let category = selectedCategory !== null ? object.categories[selectedCategory] : null;
    let question = selectedQuestion !== null ? object.categories[selectedCategory].questions[selectedQuestion] : null;

    useEffect(() => {
        document.getElementsByClassName("support")[0].scrollTo({top: 0});
    }, [selectedCategory, selectedQuestion]);

    let openFaq = (event) => {
        var ele = event.target;
        ele.parentNode.querySelectorAll("h4").forEach(e => e.dataset.open = false);
        event.target.dataset.open = true;
    }

    let faqs = (o, i) => <>
        { i ? <hr /> : <></> }
        <h4 data-open={false} onClick={e => openFaq(e)} className="button"><i className="fa-solid fa-circle-question"></i>{ o.title }</h4>
        <p>{ o.text }</p>
    </>;

    let panel = (o, i, secondPage) => <>
        <div className={ "panel small button " + ( i % 2 == 0 ? "" : "right" ) } onClick={ () => { setQuestion(secondPage ? i : null); if ( !secondPage ) setCategory(i); } } >
            <div>
                <h3>{ o.title }</h3>
                <hr />
                <p>{ o.text }</p>
                <h3>Continue<i className="fa-solid fa-circle-arrow-right"></i></h3>
            </div>
        </div> 
    </>;

    let body = (object, category, question) => {
        
        if ( category === null && question === null )
            return <>
                <h3 className="button"><i className="fa-solid fa-circle-arrow-left"></i></h3>
                <h2><b>{ object.title }</b></h2>
                <p>{ object.text }</p>
                <hr />
                { object.categories.map((o, i) => panel(o, i, 0)) }
            </>;

        if ( category !== null && question === null )
            return <>
                <h3 className="button" data-open={true} onClick={() => { setCategory(null); setQuestion(null); }}><i className="fa-solid fa-circle-arrow-left"></i>{ category.title }</h3>
                <h2><b>{ category.longtitle }</b></h2>
                <p>{ category.longtext }</p>
                <hr />
                { category.questions.map((o, i) => panel(o, i, 1)) }
                <h3>Frequently Asked Questions</h3>
                <hr />
                <div className="panel inset">
                    { category.faqs.map(faqs) }
                </div> 
            </>;

        if ( category !== null && question !== null )
            return <>
                <h3 className="button" data-open={true} onClick={() => { setQuestion(null); }}><i className="fa-solid fa-circle-arrow-left"></i>{ question.title }</h3>
                <h2><b>{ question.longtitle }</b></h2>
                <p>{ question.longtext }</p>
                <hr />
                <div className="panel medium form noback">
                    <label>Subject</label>
                    <input />
                    <label>Message</label>
                    <textarea />
                    <button>Create Ticket</button>
                </div>
                <h3>Frequently Asked Questions</h3>
                <hr />
                <div className="panel small inset">
                    { ( question.faqs ?? category.faqs ).map(faqs) }
                </div> 
            </>;

    };
    
    return (
        <div className="main">
            <Sidebar selected="support" />
            <div className="support">

                { body(object, category, question) }

            </div>
        </div>
    );
  }
  
  export default Support;