import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Details from './components/Details';
import Offers from './components/Offers';
import Applications from './components/Applications';
import Support from './components/Support';


function App() {
  return (
    <div className="App">
      <header className="header">
        <img src="logo.png" className="App-logo" alt="logo" />
        <i className="fas fa-bars"></i>
      </header>
      <main>
        <BrowserRouter>
            <Routes>
                <Route exact path="/"                       element={<Dashboard />}/>
                <Route exact path="/details"                element={<Details />}/>
                <Route exact path="/offers"                 element={<Offers />}/>
                <Route exact path="/applications"           element={<Applications />}/>
                <Route exact path="/support"                element={<Support />}/>
            </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
