import Sidebar from './Sidebar';

function Applications() {

    return (
        <div className="main">
            <Sidebar selected="applications" />
            <div className="applications">
                <h2><b>Open Applications</b></h2>
                <div className="panel small">
                </div>
                <hr />
                <h2><b>Complete Applications</b></h2>
                <div className="panel small">
                </div>
            </div>
        </div>
    );
  }
  
  export default Applications;